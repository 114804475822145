<template>
	<div v-if="computedValue === `ko`">
		<main-header></main-header>
		<menu-intro :menuKr="menuKr" :menuEn="menuEn"></menu-intro>
		<div class="container">
			<div class="userauth_wrapper">
				<div class="userauth_title">
					<div class="text_width01">
						<h1>기업회원 전환 신청서 방법 안내</h1>
					</div>
					<p>
						첫 서비스 구매 시에 구매 신청서 제출이 필요합니다.<br />
						서류 접수 후 간단한 확인 절차가 이루어집니다<br />
						업종 및 서류 사실 여부에 따라 서비스 구매가 거절될 수 있습니다
					</p>
					<div class="text_width02">
						<h2>
							서류 접수 후 검토 완료까지 영업일 3~5일 정도 소요될 수 있으며,<br />
							운영팀에서 순차적으로 검토 후 이메일로 연락드립니다
						</h2>
					</div>
				</div>
				<div class="userauth_btnbox">
					<button class="btn-fil" @click="download('1')">
						기업 전환 양식 다운
					</button>
					<button class="btn-fil" @click="download('2')">
						표준산업분류 연계표 다운
					</button>
				</div>
				<div class="userauth_manual--img">
					<img :src="Menual01" />
				</div>
				<div class="userauth_manual--img">
					<img :src="Menual02" />
				</div>
				<div class="userauth_inquire">
					<p>
						기업전환 관련하여 문의 사항이 있으시면 고객센터로 문의해 주시기
						바랍니다.
					</p>
					<h3>
						※자세한 내용은 PC버전에서 확인<br />
						부탁드립니다.
					</h3>
					<h2>고객센터 : 031-995-6409</h2>
					<button class="btn-fil">
						<router-link to="/inquiry">1:1 문의하기</router-link>
					</button>
				</div>
			</div>
		</div>
		<Footer />
	</div>
	<div v-else-if="computedValue === `en`">
		<main-header></main-header>
		<menu-intro :menuKr="menuKr" :menuEn="menuEn"></menu-intro>
		<div class="container">
			<div class="userauth_wrapper">
				<div class="userauth_title">
					<div class="text_width01">
						<h1>Guide for Corporate Member Conversion Application</h1>
					</div>
					<p>
						When making your first service purchase, a purchase application form
						is required.<br />
						After submitting the documents, a simple verification process will
						be conducted.<br />
						Depending on the industry and the accuracy of the documents, service
						purchases may be denied.
					</p>
					<div class="text_width02">
						<h2>
							It may take about 3 to 5 business days for the review process
							after document submission.<br />
							The operations team will review the documents in order and contact
							you via email.
						</h2>
					</div>
				</div>
				<div class="userauth_btnbox">
					<button class="btn-fil" @click="download('1')">
						Download Corporate Conversion Form
					</button>
					<button class="btn-fil" @click="download('2')">
						Download Standard Industry Classification Table
					</button>
				</div>
				<div class="userauth_manual--img">
					<img :src="Menual01en" />
				</div>

				<div class="userauth_inquire">
					<p>
						If you have any questions regarding the corporate transition, please
						contact our customer service center.
					</p>
					<h3>
						※Please check the detailed information<br />
						on the PC version.
					</h3>
					<h2>Customer Service: 031-995-6409</h2>
					<button class="btn-fil">
						<router-link to="/inquiry">1:1 Contact</router-link>
					</button>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
import downloadService from '../services/downloadService';
import Footer from '../components/Footer.vue';
import MainHeader from '../components/MainHeader.vue';
import MenuIntro from '../components/common/MenuIntro.vue';
import Menual01 from '../assets/images/userauth/userauth_menual01.png';
import Menual01en from '../assets/images/userauth/userauth_menual01en.png';
import Menual02 from '../assets/images/userauth/userauth_menual02.png';

export default {
	components: { MainHeader, Footer, MenuIntro },
	data() {
		return {
			// menuKr: '기업전환 신청서 안내',
			// menuEn: 'User Auth',
			Menual01,
			Menual02,
			Menual01en,
		};
	},
	computed: {
		computedValue() {
			return this.$store.state.language;
		},
		menuKr() {
			return this.computedValue === 'ko'
				? '기업전환 신청서 안내'
				: 'Guide for Corporate Member Conversion Application';
		},
		menuEn() {
			return 'User Auth';
		},
	},
	methods: {
		download(param) {
			downloadService
				.download(param)
				.then(res => {
					const url = window.URL.createObjectURL(new Blob([res.data]));
					let fileName = '';
					fileName = res.headers['content-disposition'].split('filename=')[1];
					res.headers['content-disposition'].split('filename=')[2];
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileName); //or any other extension
					document.body.appendChild(link);
					link.click();
				})
				.catch(err => {
					console.log(err);
				})
				.finally(() => {
					// whatever
				});
		},
	},
};
</script>

<style></style>
